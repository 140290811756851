import { toPng, toJpeg } from 'dom-to-image';
import { changeDpiDataUrl } from 'changedpi';

const BASE_DPI = 72;

const downloadScreenshot = async ({
  filename,
  node,
  scale,
  fileType,
  quality,
}) => {
  // https://github.com/tsayen/dom-to-image/issues/69#issuecomment-486146688
  const options = {
    height: node.offsetHeight * scale,
    width: node.offsetWidth * scale,
    filter: (el) => !el?.classList?.contains('do-not-export'),
    quality: quality / 100,
    style: {
      transform: `scale(${scale})`,
      transformOrigin: 'top left',
      width: `${node.offsetWidth}px`,
      height: `${node.offsetHeight}px`,
      backgroundImage: 'none',
    },
  };

  let dataUrl = '';

  if (fileType === 'png') {
    dataUrl = await toPng(node, options);
  } else if (fileType === 'jpg') {
    dataUrl = await toJpeg(node, options);
  }

  dataUrl = changeDpiDataUrl(dataUrl, BASE_DPI * scale);
  const link = document.createElement('a');
  link.download = `${filename}.${fileType}`;
  link.href = dataUrl;
  link.click();
};

export default downloadScreenshot;
