import { createContext, useState, useContext, useCallback } from 'react';
import { DEFAULT_APP_STATES } from 'lib/constants';

const AppStateContext = createContext({
  ...DEFAULT_APP_STATES,
  isDesktop: false,
});

const AppStateProvider = ({ children }) => {
  const [appStates, setAppStates] = useState(DEFAULT_APP_STATES);

  const isDesktop = appStates.deviceType.value === 'desktop';

  const updateAppStates = useCallback((newAppStates) => {
    setAppStates((prevAppStates) => ({
      ...prevAppStates,
      ...newAppStates,
    }));
  }, []);

  return (
    <AppStateContext.Provider
      value={{
        appStates: { ...appStates, isDesktop },
        updateAppStates,
      }}
    >
      {children}
    </AppStateContext.Provider>
  );
};

const useAppState = () => useContext(AppStateContext);

export { AppStateProvider, useAppState };
export default AppStateContext;
