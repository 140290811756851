import { createContext, useContext } from 'react';
import firebase from 'firebase/app';
import 'firebase/analytics';

const app = firebase.default;

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

class Analytics {
  constructor() {
    this.analytics = {
      logEvent: () => {},
      setCurrentScreen: () => {},
      setUserId: () => {},
    };

    if (navigator?.onLine) {
      app.initializeApp(firebaseConfig);

      app.analytics.isSupported().then((isSupported) => {
        if (isSupported) {
          this.analytics = app.analytics();
        }

        this.logEvent = (event, options) => {
          if (options) {
            this.analytics.logEvent(event, options);
          } else {
            this.analytics.logEvent(event);
          }
        };

        this.logEvent('app_initialized');
      });
    }
  }
}

const analytics = new Analytics();

const AnalyticsContext = createContext();

const AnalyticsProvider = ({ children }) => (
  <AnalyticsContext.Provider value={analytics}>
    {children}
  </AnalyticsContext.Provider>
);

const useAnalytics = () => useContext(AnalyticsContext);

export { AnalyticsContext as default, AnalyticsProvider, useAnalytics };
