import classNames from 'classnames';
import { LoaderFill } from 'components/loader/loader';
import { useOptions } from 'contexts/options-context';
import { useOutput } from 'contexts/output-context';
import { useAppState } from 'contexts/app-state-context';
import { useUpload } from 'contexts/upload-context';
import { getColorFromElement } from 'util/color';
import { UploadIcon } from 'components/icons';
import { getDevice } from './devices';
import styles from './mobile-window.module.scss';

const Placeholder = ({ children }) => (
  <article className={styles.webFramePlaceholder}>{children}</article>
);

const MobileImage = ({ image, cursor = 'default', onClick = () => {} }) => (
  <img
    className={styles.mobileScreenshot}
    src={image}
    alt="Screenshot"
    style={{ cursor }}
    onClick={onClick}
  />
);

const MobileLoader = () => (
  <div className={styles.mobileLoadingContainer}>
    <LoaderFill color="#333" />
  </div>
);

const WelcomeMessage = () => (
  <div className={styles.content}>
    <h1>
      Welcome to <strong>screenshotr</strong>
    </h1>
    <p>Create professional looking web browser mockups in a snap</p>
    <p>Just enter a URL at the top and click GO!</p>
  </div>
);

const UploadMessage = ({ isActive, device }) => (
  <div
    className={classNames(
      styles.webFramePlaceholder,
      styles.upload,
      styles.mobile,
      isActive ? styles.active : styles.inactive,
      device,
      styles[device]
    )}
    style={{ height: '100%' }}
  >
    <div className={styles.uploadContent}>
      <UploadIcon className={styles.uploadIcon} />
      <span className={styles.uploadDescription}>Drop a screenshot here!</span>
    </div>
  </div>
);

const MobileWindow = () => {
  const { options, updateOptions } = useOptions();
  const {
    mobileDevice,
    mobileOutputWidth,
    deviceColor,
    mobileSubjectWidth,
  } = options;

  const {
    height: cssHeight,
    width: cssWidth,
  } = mobileDevice.value.cssResolution;
  const scale = mobileSubjectWidth / cssWidth;
  const outputHeight = cssHeight * scale;

  const { isEyeDropperActive, isLoading } = useAppState().appStates;

  const { output, cleanURL } = useOutput();
  const { screenshot, isUpload } = output;

  const { isDragActive } = useUpload();

  const handleImageClick = async (e) => {
    if (isEyeDropperActive) {
      const backgroundColor = await getColorFromElement(e);
      updateOptions({
        background: { backgroundColor },
      });
    }
  };

  const Device = getDevice(mobileDevice.value.class);

  return (
    <article
      className={styles.mobileWindow}
      style={{
        height: outputHeight,
        width: mobileOutputWidth,
      }}
    >
      <Device
        color={deviceColor?.value || ''}
        scale={scale}
        headerText={isUpload ? '' : cleanURL}
      >
        {screenshot && !isLoading ? (
          <MobileImage
            image={screenshot}
            cursor={isEyeDropperActive ? 'crosshair' : 'default'}
            onClick={handleImageClick}
          />
        ) : (
          <Placeholder>
            {isLoading ? <MobileLoader /> : <WelcomeMessage />}
          </Placeholder>
        )}
        <UploadMessage
          isActive={isDragActive}
          device={mobileDevice.value.class}
        />
      </Device>
    </article>
  );
};

export default MobileWindow;
