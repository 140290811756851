const toBase64 = async (resp) => {
  const contentType = resp.headers.get('Content-Type');
  const buffer = await resp.arrayBuffer();
  const bytes = new Uint8Array(buffer);
  let binary = '';
  bytes.forEach((b) => {
    binary += String.fromCharCode(b);
  });
  return `data:${contentType};base64,${window.btoa(binary)}`;
};

export const pullFavicon = async (targetURL) => {
  const response = await fetch(
    `${process.env.REACT_APP_FUNCTIONS_ENDPOINT}pull-favicon`,
    {
      method: 'POST',
      body: JSON.stringify({ targetURL }),
      headers: {
        'Content-Type': 'application/json',
      },
    }
  );

  if (response.ok) {
    const data = await response.json();
    return data.favicon;
  }

  return '';
};

export const pullImage = async (targetURL, resolution, userAgent) => {
  const response = await fetch(
    `${process.env.REACT_APP_FUNCTIONS_ENDPOINT}take-screenshot`,
    {
      method: 'POST',
      body: JSON.stringify({ targetURL, resolution, userAgent }),
      headers: {
        'Content-Type': 'application/json',
        Accept: '*/*',
        'Accept-Encoding': '*',
      },
    }
  );

  if (response.ok) {
    const data = await response.json();
    return data.screenshot;
  }

  // if (response.ok) {
  //   const imageStr = await toBase64(response);
  //   return imageStr;
  // }

  return '';
};

export const getUnsplashImage = async () => {
  const response = await fetch(
    `${process.env.REACT_APP_FUNCTIONS_ENDPOINT}get-unsplash-image`,
    {
      method: 'GET',
    }
  );

  if (response.ok) {
    const data = await response.json();
    const imageResp = await fetch(data.urls.regular);
    data.imageStr = await toBase64(imageResp);
    return data;
  }

  return '';
};

export const getFullUnsplashImage = async (targetURL) => {
  const response = await fetch(
    `${process.env.REACT_APP_FUNCTIONS_ENDPOINT}get-full-unsplash-image`,
    {
      method: 'POST',
      body: JSON.stringify({ targetURL }),
      headers: {
        'Content-Type': 'application/json',
      },
    }
  );

  if (response.ok) {
    const data = await response.json();
    const image = await fetch(data.url);
    const imageStr = await toBase64(image);
    return imageStr;
  }

  return '';
};
