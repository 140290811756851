import 'styles/marvel.scss';
import 'styles/iphone-x.scss';

const MarvelBase = ({ children, scale = 1, model = '', color = '' }) => (
  <div
    className={`marvel-device ${model} ${color}`}
    style={{ transform: `scale(${scale}, ${scale})` }}
  >
    {children}
  </div>
);

const TopBar = () => <div className="top-bar" />;
const Volume = () => <div className="volume" />;
const Sleep = () => <div className="sleep" />;
const Camera = () => <div className="camera" />;
const Sensor = () => <div className="sensor" />;
const MoreSensors = () => <div className="more-sensors" />;
const Speaker = () => <div className="speaker" />;
const HomeButton = () => <div className="home" />;
const BottomBar = () => <div className="bottom-bar" />;
const Screen = ({ children }) => <div className="screen">{children}</div>;
const Inner = () => <div className="inner" />;
const Overflow = ({ children }) => <div className="overflow">{children}</div>;
const Shadow = () => <div className="shadow" />;

const LockIcon = () => (
  <svg
    className="lock-icon"
    width="64"
    height="64"
    viewBox="0 0 64 64"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M21.1667 22.6063H43.8333V19.6653C43.8333 16.5633 42.7266 13.915 40.513 11.7202C38.2995 9.52538 35.6285 8.42798 32.5 8.42798C29.3715 8.42798 26.7005 9.52538 24.487 11.7202C22.2734 13.915 21.1667 16.5633 21.1667 19.6653V22.6063ZM58 26.8203V59.786C58 60.9566 57.5868 61.9515 56.7604 62.7709C55.934 63.5903 54.9306 64 53.75 64H11.25C10.0694 64 9.06597 63.5903 8.23958 62.7709C7.41319 61.9515 7 60.9566 7 59.786V26.8203C7 25.6497 7.41319 24.6548 8.23958 23.8354C9.06597 23.016 10.0694 22.6063 11.25 22.6063H12.6667V19.6653C12.6667 14.2807 14.6146 9.65706 18.5104 5.79424C22.4062 1.93141 27.0694 0 32.5 0C37.9306 0 42.5937 1.93141 46.4896 5.79424C50.3854 9.65706 52.3333 14.2807 52.3333 19.6653V22.6063H53.75C54.9306 22.6063 55.934 23.016 56.7604 23.8354C57.5868 24.6548 58 25.6497 58 26.8203Z"
      fill="currentColor"
    />
  </svg>
);

const Header = ({ children, isDark = true }) => (
  <div className={`header ${isDark ? 'dark' : 'light'}`}>
    <div className="header-content">
      <LockIcon />
      <div className="header-text">{children}</div>
    </div>
  </div>
);

export const IphoneX = ({ children, scale = 1, headerText = '' }) => (
  <div
    className="iphone-x-new"
    style={{ transform: `scale(${scale}, ${scale})` }}
  >
    <Speaker />
    <Camera />
    <Sleep />
    <Volume />
    {headerText && <Header>{headerText}</Header>}
    <Screen>{children}</Screen>
  </div>
);

export const Iphone8 = ({ children, scale = 1, color = 'black' }) => (
  <MarvelBase model="iphone8" scale={scale} color={color}>
    <TopBar />
    <Sleep />
    <Volume />
    <Camera />
    <Sensor />
    <Speaker />
    <Screen>{children}</Screen>
    <HomeButton />
    <BottomBar />
  </MarvelBase>
);

export const Iphone8Plus = ({ children, scale = 1, color = 'black' }) => (
  <MarvelBase model="iphone8plus" scale={scale} color={color}>
    <TopBar />
    <Sleep />
    <Volume />
    <Camera />
    <Sensor />
    <Speaker />
    <Screen>{children}</Screen>
    <HomeButton />
    <BottomBar />
  </MarvelBase>
);

export const Iphone5S = ({ children, scale = 1, color = 'black' }) => (
  <MarvelBase model="iphone5s" scale={scale} color={color}>
    <TopBar />
    <Sleep />
    <Volume />
    <Camera />
    <Sensor />
    <Speaker />
    <Screen>{children}</Screen>
    <HomeButton />
    <BottomBar />
  </MarvelBase>
);

export const Iphone5C = ({ children, scale = 1, color = 'white' }) => (
  <MarvelBase model="iphone5c" scale={scale} color={color}>
    <TopBar />
    <Sleep />
    <Volume />
    <Camera />
    <Sensor />
    <Speaker />
    <Screen>{children}</Screen>
    <HomeButton />
    <BottomBar />
  </MarvelBase>
);

export const Iphone4S = ({ children, scale = 1, color = 'black' }) => (
  <MarvelBase model="iphone4s" scale={scale} color={color}>
    <TopBar />
    <Sleep />
    <Volume />
    <Camera />
    <Sensor />
    <Speaker />
    <Screen>{children}</Screen>
    <HomeButton />
    <BottomBar />
  </MarvelBase>
);

export const IpadMini = ({ children, scale = 1, color = 'black' }) => (
  <MarvelBase model="ipad" scale={scale} color={color}>
    <Camera />
    <Screen>{children}</Screen>
    <HomeButton />
  </MarvelBase>
);

export const GalaxyNote8 = ({ children, scale = 1 }) => (
  <MarvelBase model="note8" scale={scale}>
    <Inner />
    <Overflow>
      <Shadow />
    </Overflow>
    <Speaker />
    <Sensor />
    <MoreSensors />
    <Sleep />
    <Volume />
    <Camera />
    <Screen>{children}</Screen>
  </MarvelBase>
);

export const Nexus5 = ({ children, scale = 1 }) => (
  <MarvelBase model="nexus5" scale={scale}>
    <TopBar />
    <Sleep />
    <Volume />
    <Camera />
    <Screen>{children}</Screen>
  </MarvelBase>
);

export const Lumia920 = ({ children, scale = 1, color = 'black' }) => (
  <MarvelBase model="lumia920" scale={scale} color={color}>
    <TopBar />
    <Sleep />
    <Volume />
    <Camera />
    <Screen>{children}</Screen>
  </MarvelBase>
);

export const GalaxyS5 = ({ children, scale = 1, color = 'white' }) => (
  <MarvelBase model="s5" scale={scale} color={color}>
    <TopBar />
    <Sleep />
    <Camera />
    <Sensor />
    <Speaker />
    <Screen>{children}</Screen>
    <HomeButton />
  </MarvelBase>
);

export const HtcOne = ({ children, scale = 1 }) => (
  <MarvelBase model="htc-one" scale={scale}>
    <TopBar />
    <Camera />
    <Sensor />
    <Speaker />
    <Screen>{children}</Screen>
  </MarvelBase>
);

export const getDevice = (model) => {
  switch (model) {
    case 'iphone8':
      return Iphone8;

    case 'iphone8plus':
      return Iphone8Plus;

    case 'iphone5s':
      return Iphone5S;

    case 'iphone5c':
      return Iphone5C;

    case 'iphone4s':
      return Iphone4S;

    case 'ipad':
      return IpadMini;

    case 'note8':
      return GalaxyNote8;

    case 'nexus5':
      return Nexus5;

    case 'lumia920':
      return Lumia920;

    case 's5':
      return GalaxyS5;

    case 'htc-one':
      return HtcOne;

    case 'iphone-x':
    default:
      return IphoneX;
  }
};
