import { useRef, useState } from 'react';
import classNames from 'classnames';
import { useOptions } from 'contexts/options-context';
import { useOutput } from 'contexts/output-context';
import { useAppState } from 'contexts/app-state-context';
import useComponentSize from 'hooks/use-component-size';
import ZoomPanWrapper from './zoom-pan-wrapper';
import CheckboardBackground from './checkboard-background';
import styles from './app-body.module.scss';

const AppBody = ({ children }) => {
  const { isDesktop } = useAppState().appStates;
  const { options } = useOptions();
  const {
    maxOutputWidth,
    maxOutputHeight,
    desktopOutputWidth,
    desktopOutputHeight,
    mobileOutputWidth,
    mobileOutputHeight,
    background,
  } = options;

  const outputSize = isDesktop
    ? {
        width: desktopOutputWidth,
        height: desktopOutputHeight,
      }
    : {
        width: mobileOutputWidth,
        height: mobileOutputHeight,
      };

  const { exportRef } = useOutput();

  const appBodyRef = useRef();
  const bodySize = useComponentSize(appBodyRef);
  const { width, height } = bodySize;

  const [zoomPanState, setZoomPanState] = useState({
    scale: 1,
    translation: {
      x: 0,
      y: 0,
    },
  });

  const zoom = Math.round(zoomPanState.scale * 100);

  return (
    <article className={styles.appBody} ref={appBodyRef}>
      {zoom !== 100 && (
        <div
          className={classNames(
            styles.zoomPanControl,
            styles.zoomPanControl_label
          )}
        >
          Zoom: <strong>{zoom}%</strong>
        </div>
      )}

      <ZoomPanWrapper
        width={width}
        height={height}
        maxWidth={maxOutputWidth}
        maxHeight={maxOutputHeight}
        zoomPanState={zoomPanState}
        setZoomPanState={setZoomPanState}
      >
        <article className={styles.exportBoundary} style={bodySize}>
          <article className={styles.export} style={outputSize} ref={exportRef}>
            <CheckboardBackground />
            <div className={styles.exportBackground} style={background} />
            {children}
          </article>
        </article>
      </ZoomPanWrapper>
    </article>
  );
};

export default AppBody;
