import { useState, useCallback, useEffect } from 'react';
import classNames from 'classnames';
import { getUnsplashImage, getFullUnsplashImage } from 'util/api';
import { BasicLoaderFill } from 'components/loader/loader';
import { UploadIcon } from 'components/icons';
import styles from './background-picker.module.scss';

const Attribution = ({ user, link }) => (
  <span className={styles.attribution}>
    Photo by{' '}
    <a
      href={`${link}?utm_source=screenshotr&utm_medium=referral`}
      target="_blank"
      rel="noopener noreferrer"
    >
      {user}
    </a>{' '}
    on{' '}
    <a href="https://unsplash.com/" target="_blank" rel="noopener noreferrer">
      Unsplash
    </a>
  </span>
);

const BackgroundImagePicker = ({ handleChange = () => {} }) => {
  const [isUnsplashActive, setUnsplashActive] = useState(true);
  const [isBackgroundLoading, setBackgroundLoading] = useState(false);
  const [backgroundImage, setBackgroundImage] = useState('');

  const getBackgroundImage = useCallback(() => {
    setBackgroundLoading(true);
    setUnsplashActive(true);
    getUnsplashImage().then((img) => {
      setBackgroundImage(img);
      setBackgroundLoading(false);
    });
  }, []);

  useEffect(() => {
    if (!backgroundImage) {
      getBackgroundImage();
    }
  }, [backgroundImage, getBackgroundImage]);

  const handleFile = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      setUnsplashActive(false);
      const reader = new FileReader();
      reader.addEventListener('load', () => {
        setBackgroundImage({
          imageStr: reader.result,
        });
        handleChange({
          backgroundImage: `url(${reader.result})`,
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
        });
      });
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  const useUnsplashImage = async () => {
    console.log(backgroundImage.links.download_location);
    if (isUnsplashActive && backgroundImage?.links?.download_location) {
      setBackgroundLoading(true);
      const unsplashImage = await getFullUnsplashImage(
        backgroundImage.links.download_location
      );

      handleChange({
        backgroundImage: `url(${unsplashImage})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
      });
      setBackgroundLoading(false);
    }
  };

  return (
    <div className={styles.backgroundImagePicker}>
      <article className="input-container">
        <h3 className="label">Custom Image</h3>
        <label
          htmlFor="background-image-input"
          className={classNames('button', styles.uploadBackgroundButton)}
          style={{
            justifyContent: 'center',
            marginBottom: 0,
          }}
        >
          <input
            id="background-image-input"
            className="hidden-input"
            type="file"
            accept="image/*"
            onChange={handleFile}
          />
          <span>Upload Image</span>
          <UploadIcon />
        </label>
      </article>
      <article className="input-container">
        <h3 className="label">Random Unsplash Image</h3>
        <section className="button-row">
          <button
            type="button"
            className={classNames('button', styles.eyeDropperButton)}
            onClick={getBackgroundImage}
          >
            <span>New Image</span>
          </button>
          <button
            type="button"
            className={classNames('button', styles.eyeDropperButton)}
            onClick={useUnsplashImage}
          >
            <span>Use Image</span>
          </button>
        </section>
      </article>
      <article className={styles.backgroundImageContainer}>
        <BasicLoaderFill
          className={styles.backgroundLoader}
          style={{ zIndex: isBackgroundLoading ? 2 : -1 }}
        />
        {backgroundImage.imageStr && (
          <img
            className={styles.sidebarBackgroundImage}
            src={backgroundImage.imageStr}
            alt="Background"
          />
        )}
        {backgroundImage?.user && (
          <Attribution
            user={backgroundImage.user.name}
            link={backgroundImage.user.links.html}
          />
        )}
      </article>
    </div>
  );
};

export default BackgroundImagePicker;
